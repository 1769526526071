<template>
  <page-header-wrapper :title='this.$route.meta.title'>

    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card :loading="loading">
          <div class="card_content">
            <a-row>
              <a-col :span="24"><span class="card_title">阶段名称</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="24"><span class="card_title color-red" style="font-size: 26px">{{dashboard.goalName}}（{{dashboard.status}}）</span></a-col>
            </a-row>
          </div>

        </a-card>
      </a-col>
      <a-col :sm='12' :md='6' :xl='15' :style="{ marginBottom: '24px' }">
        <a-card :loading="loading">
          <div class="card_content">
            <a-row style="height: 40px;">
              <a-col :span="3"><span class="card_title">首次订单数</span></a-col>
              <a-col :span="3"><span class="card_title">首次订单金额</span></a-col>
              <a-col :span="3"><span class="card_title">复购订单数</span></a-col>
              <a-col :span="3"><span class="card_title">复购订单金额</span></a-col>
              <a-col :span="3"><span class="card_title">上月份额总数</span></a-col>
              <a-col :span="3"><span class="card_title">份额总数</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row >
              <a-col :span="3"><span class="color-red">{{ dashboard.firstCount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.firstAmount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.repeatCount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.repeatAmount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.lastMonthTotalShare }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.totalShare }}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-card :bordered='false'>
      <a-form-model ref='form' :model='form' layout='inline'>
        <a-form-model-item prop='orderId'>
          <a-input v-model='form.orderId' style="width: 200px" placeholder="订单号" />
        </a-form-model-item>
      <a-form-model-item prop='userName'>
        <a-input v-model='form.userName' style="width: 200px" placeholder="姓名" />
      </a-form-model-item>

      <a-form-model-item prop='mobile'>
        <a-input v-model='form.mobile' style="width: 200px" placeholder="手机号" />
      </a-form-model-item>

        <a-form-model-item prop='servicePresidentName'>
          <a-input v-model='form.servicePresidentName' style="width: 200px" placeholder="分公司简称" />
        </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='buyType' slot-scope='text, record'>
          <template>
            {{text==0?'首次':'复购'}}
          </template>
        </span>

        <span slot='username' slot-scope='text, record'>
          <template>
            {{text==undefined?record.servicePresidentName:text}}
          </template>
        </span>

        <span slot='afterShareTotal' slot-scope='text, record'>
          <template>
            {{record.shareValue+record.beforeShare}}
          </template>
        </span>
      </s-table>
    </a-card>


  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { isVideoUrl } from '@/utils/util'
import moment from "moment";
import {queryShareDetailList, queryShareHeard} from "@/api/share";


const columns = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '方式',
    dataIndex: 'buyType',
    scopedSlots: { customRender: 'buyType' }
  },
  {
    width: 130,
    title: '订单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 100,
    title: '姓名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    width: 100,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 80,
    title: '份额值',
    dataIndex: 'beforeShare',
    scopedSlots: { customRender: 'afterShareTotal' }
  },
  {
    width: 80,
    title: '份额数',
    dataIndex: 'shareCount',
    scopedSlots: { customRender: 'shareCount' }
  },
  {
    width: 80,
    title: '累计值',
    dataIndex: 'cumulativeValue',
    scopedSlots: { customRender: 'cumulativeValue' }
  },
  {
    width: 100,
    title: '商户简称',
    dataIndex: 'servicePresidentName',
    scopedSlots: { customRender: 'servicePresidentName' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
]

export default {
  name: 'ProposalEdit',

  data() {
    return {
      form: {
      },
      loading: true,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
      dashboard: {}, //表头用户数统计
      id:"",
      status:"",
      // 表头
      columns,
      parameter:{},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.parameter = parameter;
        let params = Object.assign(parameter, {id:this.id},this.form);
        return queryShareDetailList(params).then(datum => {
            return datum
          })
      }
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.handleInitial();
    // this.getLocalData();
  },
  activated() {
    this.handleInitial();
    this.getLocalData();
    // debugger;
  },
  methods: {
    getLocalData(){
    this.$refs.table.refresh(true)

    },
    handleSubmit() {
      this.getHeaderData(this.id);
      this.$refs.table.refresh(true);
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleInitial() {
        this.id = this.$route.query.id;
        this.status = this.$route.query.status;
        this.brandCode = this.$route.query.brandCode;
        this.getHeaderData(this.id);
    },
    getHeaderData(id){
      this.loading = true;
      queryShareHeard(Object.assign({id:id,queryType:'1',brandCode:this.brandCode}, this.form)).then(result =>{
        result.status = this.getStatusStr(this.status);
        this.dashboard = result;
        this.loading = false;
        // debugger;
      });
    },
    getStatusStr(text) {
      if(text =='1'){
        return '未开始';
      } else if(text =='2'){
        return '进行中';
      } else if(text =='3'){
        return '已完成';
      }
    },
  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}
</style>
